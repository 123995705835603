interface Omit {
  <T extends object, K extends [...(keyof T)[]]>
  (obj: T, ...keys: K): {
    [K2 in Exclude<keyof T, K[number]>]: T[K2]
  }
}

export const omit: Omit = (obj, ...keys) => {
  const ret = {} as {
    [K in keyof typeof obj]: (typeof obj)[K]
  };
  let key: keyof typeof obj;
  for (key in obj) {
    if (!(keys.includes(key))) {
      ret[key] = obj[key];
    }
  }
  return ret;
};

export const cloneDeep = <T>(target: T): T => {
  if (target === null) {
    return target;
  }
  if (target instanceof Date) {
    return new Date(target.getTime()) as any;
  }
  if (target instanceof Array) {
    const cp = [] as any[];
    (target as any[]).forEach((v) => { cp.push(v); });
    return cp.map((n: any) => cloneDeep<any>(n)) as any;
  }
  if (typeof target === 'object' && Object.keys(target).length > 0) {
    const cp = { ...(target as { [key: string]: any }) } as { [key: string]: any };
    Object.keys(cp).forEach(k => {
      cp[k] = cloneDeep<any>(cp[k]);
    });
    return cp as T;
  }
  return target;
};
